import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  SETTINGS_REQUESTED,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
  SETTINGS_UPDATE_REQUESTED,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,
} from '../types';

export const settingsRequested = () => {
  return {
    type: SETTINGS_REQUESTED
  }
};

export const settingsSuccess = (settings) => {
  return {
    type: SETTINGS_SUCCESS,
    payload: settings
  }
};

export const settingsFailure = () => {
  return {
    type: SETTINGS_FAILURE
  }
};

export const settingsUpdateRequested = () => {
  return {
    type: SETTINGS_UPDATE_REQUESTED
  }
};

export const settingsUpdateSuccess = (settings) => {
  return {
    type: SETTINGS_UPDATE_SUCCESS,
    payload: settings
  }
};

export const settingsUpdateFailure = () => {
  return {
    type: SETTINGS_UPDATE_FAILURE
  }
};

export const getSettings = (ecwidPayload) => {
  return (dispatch) => {
    dispatch(settingsRequested());

    let endPoint = Config.baseUrl + Config.settings;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(settingsSuccess(response.data));
      })
  }
}


export const updateSettings = (ecwidPayload, settings) => {
  return (dispatch) => {
    dispatch(settingsUpdateRequested());

    let endPoint = Config.baseUrl + Config.settings;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { 'settings': settings } , { headers: headers })
      .then((response) => {
        dispatch(settingsUpdateSuccess(response.data));
      })
  }
}